/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Old+Standard+TT&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Josefin+Sans:wght@200&family=Old+Standard+TT:ital@0;1&family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Old Standard TT', serif; */
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}
body {
  width: 100%;
}
/* -------------------------------QUALITY MATERIAL-------------------- */
.fix-size {
  height: 210px;
}

/* .navbar-nav .dropdown-menu {
  position: unset !important;
} */

/* -------------------------------Fade Animation is Start-------------------- */
#HeadingText-Animation {
  cursor: pointer;
  font-size: 30px;
  transition: font-size 0.5s ease; /* Specify transition property */
}
#HeadingText-Animation:hover {
  font-size: 35px;
}
.logosize {
  width: 20px;
}
.font-size {
  font-size: 28px;
}
.mar-left {
  margin-left: 82px;
}

.para-width {
  width: 39%;
  justify-content: left;
  display: flex;
  /* justify-content: left;
  display: flex; */
}
.text-deco {
  font-size: 30px;
  color: #000000;
}

.p-top {
  padding-top: 72px !important;
}
.p-top p {
  margin-bottom: 0px !important;
}
#add {
  font-weight: 600;
}

.leftt {
  margin-left: 155px;
}
.just p {
  text-align: left;
}

.looseDiamond{
  width: 50%;
}

/* #zoom-loop-animation {
  cursor: pointer;
  width: 35px;
  animation: increaseSize 1s infinite alternate; 
} */

@keyframes increaseSize {
  0% {
    width: 35px; /* Start size */
  }
  100% {
    width: 40px; /* End size */
  }
}

#zoomText-loop-animation {
  cursor: pointer;
  font-size: 15px;
  animation: increaseSize 1s infinite alternate; /* Use animation instead of transition */
}

@keyframes increaseSize {
  0% {
    font-size: 10px; /* Start size */
  }
  100% {
    font-size: 15px; /* End size */
  }
}

/* ProductGridLeft.css */

.fade-left {
  animation: fadeLeftAnimation 0.5s ease forwards;
}

@keyframes fadeLeftAnimation {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* -------------------------------Fade Animation is Closed-------------------- */

/* --------------------Text Selection Color Change------------------- */
::-moz-selection {
  color: rgb(2, 127, 199);
}

::selection {
  color: rgb(2, 127, 199);
}
.navbar-second-logo {
  width: 250px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.navbar-logo {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
/* Shapes of Dimond Css */
.dimnd-sep {
  transition: 0.7s;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  display: block;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  filter: grayscale(100%);
}
.dimnd-sep:hover {
  transition: 0.7s;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  filter: grayscale(0.5%);
}

#shopNowBox {
  border-radius: 50px 0 50px 0;
  background-color: rgb(255, 255, 255);
}

#shopNowBox:hover {
  border-radius: 0 50px 0 50px;
  background-color: rgb(255, 255, 255);
}

#TTFonts {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}
#DancingFonts {
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1px;
}
#instaStoryBox {
  transition: all 0.5s;
}
#instaStoryBox:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.669);
}
.parag-width {
  width: 114%;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;

  &:before {
    content: "";
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
}
#totalProducts {
  border: 1px solid black;
  border-radius: 60px 0 60px 0;
  transition: all 1s;
}
#totalProducts:hover {
  border-radius: 0 60px 0 60px;
}
#VideoPointers {
  cursor: pointer;
}
#chooseAProductCard:hover {
  box-shadow: 5px 2px 10px rgba(82, 82, 82, 0.701) inset,
    5px 2px 10px rgba(0, 0, 0, 0.484);
  cursor: pointer;
}
.diamondCard-dropUpMenu {
  background-color: white;
  border-radius: 7px;
}
.diamondCard-dropUpMenu:hover {
  background-color: #4f4e4b;
  border: 1px solid black;
  color: white;
  cursor: pointer;
}
#LoginMainBox {
  background: url("../public/LoginBack.jpg");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.diamond-gallery {
  display: flex;
  flex-wrap: nowrap; /* Keep all images in a single line */
  justify-content: center;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.diamond-image {
  flex: 0 0 auto; /* Prevent shrinking of images */
  width: 200px; /* Base image width */
  margin-right: 10px; /* Space between images */
}

/* Custom scroll bar (optional) */
.diamond-gallery::-webkit-scrollbar {
  height: 8px;
}

.diamond-gallery::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

/* ----------------Hover Button Css Start--------------------- */

#hoverButton {
  display: inline-block;
  position: relative;
  background: none;
  border: none;
  color: black;
  font-size: 15px;
  cursor: pointer;
  margin: 20px 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: 3px 3px 0px rgba(121, 121, 121, 0.986);
}
#hoverButton:hover {
  box-shadow: none;
}
#hoverButtonSpan {
  display: block;
  padding: 20px 50px;
}
#hoverButton::before,
#hoverButton::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: black;
}

#hoverButtonSpan::before,
#hoverButtonSpan::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: black;
}
#hoverButton:hover::before,
#hoverButton:hover::after {
  width: 100%;
}
#hoverButton:hover #hoverButtonSpan::before,
#hoverButton:hover #hoverButtonSpan::after {
  height: 100%;
}

/*----- #hoverButton 1 -----*/
.hoverButtonClassname::before,
.hoverButtonClassname::after {
  transition-delay: 0.2s;
}
.hoverButtonClassname #hoverButtonSpan::before,
.hoverButtonClassname #hoverButtonSpan::after {
  transition-delay: 0s;
}
.hoverButtonClassname::before {
  right: 0;
  top: 0;
}
.hoverButtonClassname::after {
  left: 0;
  bottom: 0;
}
.hoverButtonClassname #hoverButtonSpan::before {
  left: 0;
  top: 0;
}
.hoverButtonClassname #hoverButtonSpan::after {
  right: 0;
  bottom: 0;
}
.hoverButtonClassname:hover::before,
.hoverButtonClassname:hover::after {
  transition-delay: 0s;
}
.hoverButtonClassname:hover #hoverButtonSpan::before,
.hoverButtonClassname:hover #hoverButtonSpan::after {
  transition-delay: 0.2s;
}
.img-fix {
  height: 102vh !important;
  width: 100%;
}

/* ----------------Hover Button Css Closed--------------------- */

/* ---------------------------Hover Text to Underline Start----------------------- */
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #ffffff;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fdfeff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* ---------------------------Hover Text to Underline Close----------------------- */

#facebook-icon:hover {
  color: #1877f2;
}
#twitter-icon:hover {
  color: #1da1f2;
}
#google-icon:hover {
  background-image: linear-gradient(to top, #4285f4, #0f9d58, #f4b400, #db4437);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
#instagram-icon:hover {
  background-image: linear-gradient(
    to top,
    red,
    orange,
    rgb(153, 153, 0),
    green,
    blue,
    indigo,
    violet
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}
#linkedin-icon:hover {
  color: #0a66c2;
}
#atYourService {
  cursor: pointer;
}
#Scroll-to-Top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  font-size: xx-large;
  color: black;
  z-index: 2;
  transform: rotate(-90deg);
  transition: all 0.5s;
  cursor: pointer;
}
#Scroll-to-Top:hover {
  transform: rotate(0deg);
}
#dropup-mobileApp {
  position: fixed;
  left: 50px;
  bottom: 50px;
  z-index: 2;
  cursor: pointer;
}

/* -------------------------------DropUp App Hover Button-------------------- */
.hover-dropup-container {
  position: relative;
  display: inline-block;
}

.hover-dropup-button {
  background-color: #000000;
  color: rgb(255, 255, 255);
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.hover-dropup-content {
  display: none;
  position: absolute;
  min-width: 56px;
  z-index: 1;
  bottom: 100%;
}

.hover-dropup-container:hover .hover-dropup-content {
  display: block;
}
#app-dropup-btn {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 5px;
}

.hover-dropup-content #app-dropup-btn {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.hover-dropup-content #app-dropup-btn:hover {
  background-color: #f1f1f1;
}
/* -------------------------------------------------- */
.moreaboutskylab {
  width: 75%;
}
.title-skylab {
  position: relative;
  padding: 20px;
  /* right: 150px; */
}
.title-school {
  position: relative;
  right: 215px;
  color: #000000;
}
.title-school-long {
  position: relative;
  right: 99px;
  color: #000000;
}
.img-fluid{
  max-width: 100% !important;
}
.no-scroll-gallery {
  display: flex;
  flex-wrap: wrap;  /* Ensures the images wrap into multiple rows */
  justify-content: center;
  gap: 15px;  /* Adds space between the images */
}
.diamond-image {
  width: calc(11% - 15px);  /* Adjusts the image width based on screen size */
  height: auto;
}
.jwelery-fix{
  height: 103vh !important;
}

.fullscreen-video{
  height: 100%  !important;
}


/* ---------------------------------------------max-width: 3000px------------------------------ */

@media screen and (max-width: 3000px) {
  #HeadingText-Animation {
    font-size: 30px;
  }
  #HeadingText-Animation:hover {
    font-size: 35px;
  }
  #navbar-name {
    padding: 0px 30px;
  }
  #QualityMaterialsTextBox {
    width: 50%;
  }
  #totalProductText {
    font-size: 35px;
  }
  #skylabStory {
    width: 100%;
    height: 100%;
  }
  #homepage-video-btn-div {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 118.5%;
    /* background-image: linear-gradient(180deg, rgba(15, 15, 15, 0.651), rgba(15, 15, 15, 0.671)); */
  }
  [class*="btn-outline-"] {
    --mdb-btn-border-width: 5px !important;
    font-size: 15px !important;
  }
  #homepage-video-btn {
    position: relative;
    top: 80%;
    border-width: 400 !important;
  }
  #storySliderText {
    color: white;
    font-size: 18px;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #storySliderbox {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #storySliderImage {
    width: 60%;
  }
  #navbarLoginIcon {
    position: absolute;
    top: 30px;
    right: 40px;
  }
  #NavbarTopText img {
    height: 300px;
    width: 100%;
  }

  .img-fix {
    width: 100%;
    height: 101vh !important;
  }
  .jwelery-fix{
    height: 100vh;
  }
  #TopScroll img {
    width: 100%;
  }
  .jewelery-gif img {
    width: 102%;
  }
 
}

/* ---------------------------------------------max-width: 1440px------------------------------ */
@media screen and (max-width: 1440px) {
  #HeadingText-Animation {
    font-size: 30px;
  }
  #HeadingText-Animation:hover {
    font-size: 35px;
  }
  #homepage-video-btn-div {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 100vh;
    /* background-image: linear-gradient(180deg, rgba(15, 15, 15, 0.651), rgba(15, 15, 15, 0.671)); */
  }
  [class*="btn-outline-"] {
    --mdb-btn-border-width: 3px !important;
    font-size: 10px !important;
  }
  #navbar-name {
    padding: 0px 25px;
  }
  #navbarLoginIcon {
    position: absolute;
    top: 28px;
    right: 40px;
  }
  #videoofdaimond video,
  .video-media video {
    width: 100%;
  }
  #NavbarTopText img {
    height: 400px;
    width: 100% !important;
  }
  .img-fix {
    width: 100%;
    height: 80vh;
  }
  .jewelery-gif img {
    width: 100%;
    
  }
  .mar-left {
    margin-left: 0px;
  }
  .title-skylab {
    position: relative;
    padding: 20px;
    right: 50px;
  }
  .jwelery-fix{
    height: 78vh;
  }
  .fullscreen-video video{
    width: 100%;
    height: 628px !important;
  }
}

@media screen and (max-width: 1200px) {

  .navbar-nav .dropdown-menu {
    position: unset !important;
  } 
  .diamond-gallery {
    flex-wrap: wrap;
    overflow-x: auto;
    gap: 22px;
}
#homepage-video-btn-div {
  height: 100vh;
}
.jwelery-fix{
  height: 78vh;
}
.fullscreen-video video{
  width: 100%;
  height: 600px !important;
}

}
/* ------------------------------------------------max-width: 1024px------------------------------- */
@media screen and (max-width: 1024px) {
  #HeadingText-Animation {
    font-size: 25px;
  }
  .diamond-gallery {
    flex-wrap: wrap;
    overflow-x: auto;
    gap: 22px;
}
#QualityMaterialsTextBox {
  width: 100%;
}
  #HeadingText-Animation:hover {
    font-size: 30px;
  }
  #navbar-name {
    padding: 30px;
  }
  #totalProductText {
    font-size: 35px;
  }
  #homepage-video-btn-div {
    height: 100vh;
  }
  #homepage-video-btn {
    display: none;
  }
  #homepage-video-btn {
    top: 80%;
  }
  #storySliderbox {
    height: 370px;
  }
  #storySliderText {
    font-size: 15px;
  }
  #navbarLoginIcon {
    position: absolute;
    top: 36px;
    right: 40px;
  }
  #TopScroll img {
    width: 100%;
    /* padding-bottom: 20px; */
  }
  #videoofdaimond,
  .video-media {
    flex-direction: column;
    gap: 2rem;
  }
  .img-fix {
    width: 100%;
    height: 60vh !important;
  }

    .diamond-image {
      width: calc(33.33% - 15px);  /* Adjusts image size for smaller screens */
    }
    .fullscreen-video video{
      width: 100%;
      height: 520px !important;
    }
   
  
}

/* -------------------------------------max-width: 768px--------------------------- */
@media screen and (max-width: 768px) {
  #HeadingText-Animation {
    font-size: 25px;
  }
  #HeadingText-Animation:hover {
    font-size: 27px;
  }
  #navbar-name {
    padding: 0px;
  }
  img.px-5.navbar-second-logo {
    margin: -22px;
  }
  #carouselExampleDark {
    height: 300px;
    padding-top: 50px;
  }
  #QualityMaterialsTextBox {
    width: 100%;
  }
  #NavbarTopText img {
    height: 300px;
    width: 100%;
  }
  #totalProductText {
    font-size: 25px;
  }
  #homepage-video-btn-div {
    height: 65.4%;
  }
  #homepage-video-btn {
    display: none;
  }
  #storySliderText {
    font-size: 15px;
  }
  #storySliderbox {
    height: 250px;
  }
  #storySliderImage {
    width: 80%;
  }
  #navbarLoginIcon {
    position: absolute;
    top: 30px;
    right: 60px;
  }
  #TopScroll img {
    width: 100%;
  }
  .d-flex {
    justify-content: space-around; /* Space the videos evenly */
  }
  .parag-width {
    width: 100%;
    padding-top: 180px;
  }

  #videoofdaimond,
  .video-media {
    flex-direction: column;
    gap: 2rem;
  }
  .img-fix {
    width: 100%;
    height: 60vh !important;
  }
  #QualityMaterialsTextBox {
    width: 100%;
  }
  .navbar-nav .dropdown-menu {
  position: unset !important;
} 
.diamond-image {
  width: calc(50% - 15px); 
}
}
@media screen and (max-width: 625px) {
.navbar-logo{
  display: none;

}
}

/* ----------------------------------------max-width: 425px------------------------------- */
@media screen and (max-width: 425px) {
  #HeadingText-Animation {
    font-size: 23px;
  }
  #HeadingText-Animation:hover {
    font-size: 25px;
  }
  #navbar-name {
    padding: 0px;
  }
  .navbar-logo {
    width: 80%;
    height: 70%;
  }
  #homepage-video-btn-div {
    height: 36%;
  }
  #homepage-video-btn {
    display: none;
  }
  #storySliderText {
    font-size: 10px;
    line-height: 12px;
  }
  #storySliderbox {
    height: 230px;
  }
  #storySliderImage {
    width: 96%;
  }
  #navbarLoginIcon {
    position: absolute;
    top: 30px;
    right: 60px;
  }
  #TopScroll img {
    width: 100%;
  }

  .video-media video {
    flex-basis: calc(50% - 1rem); /* Show 2 videos per row */
  }
  .img-fix {
    width: 100%;
    height: 50vh;
  }
  .jewelery-gif img {
    width: 100%;
  }
  #hoverButton {
    position: relative;
    right: 0 !important;
  }
  #HeadingText-Animation {
    right: 0 !important;
    position: relative;
  }
  .moreaboutskylab {
    width: 100%;
  }
  .title-skylab {
    position: relative;
    padding: 20px;
    right: 20px;
  }
  .title-school {
    position: relative;
    right: 0px;
    color: #000000;
  }
  .parag-width {
    width: 100%;
    padding-top: 180px;
  }
  .title-school-long {
    position: relative;
    right: 0px;
    color: #000000;
  }
  .diamond-image {
    width: calc(100% - 15px);  /* Adjusts image size for mobile screens */
  }
  .jwelery-fix{
    height: 70vh;
    width: 100%;
  }
}

/* ---------------------------------------max-width: 320px----------------------------- */
@media screen and (max-width: 320px) {
  img.px-5.navbar-second-logo {
    margin: -22px;
  }
  #TopScroll img {
    width: 100%;
  }
  .video-media video {
    flex-basis: 100%; /* Show 2 videos per row */
  }
  .img-fix {
    width: 70vh;
    height: 70vh;
  }
  .jewelery-gif img {
    width: 100%;
    height: 50vh !important;
  }
  .parag-width {
    width: 100%;
    padding-top: 180px;
  }
  #hoverButton {
    position: relative;
    right: 0 !important;
  }
  .moreaboutskylab {
    width: 100%;
  }
  .title-skylab {
    position: relative;
    padding: 20px;
    right: 20px;
  }
  .title-school {
    position: relative;
    right: 0px;
    color: #000000;
  }
  .title-school-long {
    position: relative;
    right: 0px;
    color: #000000;
  }
  .jwelery-fix{
    height: 50vh;
    width: 100%;
  }
  

}
